<template>
  <div class="container_box">
    <!--头部筛选区域-->
    <div class="filter_box">
      <div style="paddingTop:15px;">
        {{ $t('key1005770') }}
        <Input v-model.trim="pickList" clearable ref="packListIpt" autofocus style="width:300px;marginLeft:15px;" @on-enter="pickStart"></Input>
      </div>
    </div>
    <h2 class="title">{{ $t('key1005771') }}</h2>
    <!--列表区域-->
    <div class="table_box">
      <Table
        border
        highlight-row
        :loading="tableLoading"
        max-height="560"
        :columns="sortingColumn"
        :data="sortingData">
      </Table>
    </div>
    <!-- 拣货单异常 -->
    <Modal v-if="pickingListStatus" v-model="pickingListModelStatus" width="500" :title="$t('key1005761')">
      <div style="padding:10px 20px;">
        <Row>
          <Col span="6">
            <div>
              <Icon type="md-information-circle" color="#2b85e4" style="font-size:50px"></Icon>
            </div>
          </Col>
          <Col
            v-if="errorCode === 116001"
            span="16"
            style="font-size:16px">{{ $t('key1005762').replace('{pickList}', pickListText) }}
          </Col>
          <Col
            v-if="errorCode === 116002"
            span="16"
            style="font-size:16px">{{ $t('key1005763').replace('{pickList}', pickListText) }}
          </Col>
          <Col v-if="errorCode === 116119" span="16" style="font-size:16px">{{ $t('key1005772') }}
          </Col>
          <Col v-if="errorCode === 116120" span="16" style="font-size:16px">{{ $t('key1005773') }}
          </Col>
          <Col v-if="errorCode === 116003" span="16" style="font-size:16px">
            <div>{{ $t('key1005774') }}</div>
            <div style="marginTop:15px;">
              {{ $t('key1002394') }}{{ pickNo }}
            </div>
            <div style="marginBottom:15px;">
              {{ $t('key1005775') }}{{ pickStartTime }}
            </div>
            <div>
              {{ $t('key1005764').replace('{pickList}', pickListText) }}
            </div>
          </Col>
          <Col
            v-if="errorCode === 116004"
            span="16"
            style="font-size:16px">{{ $t('key1005765').replace('{pickList}', pickListText) }}
          </Col>
          <Col v-if="errorCode === 116005" span="16" style="font-size:16px">
            <div style="marginBottom:15px;">
              {{ $t('key1005766').replace('{pickList}', pickListText) }}
            </div>
            <RadioGroup v-model="restPick">
              <Radio label="no">
                <span>{{ $t('key1005776') }}</span>
              </Radio>
              <Radio label="yes">
                <span>{{ $t('key1005777') }}</span>
              </Radio>
            </RadioGroup>
          </Col>
          <Col
            v-if="errorCode === 116006"
            span="16"
            style="font-size:16px">{{ $t('key1005767').replace('{pickList}', pickListText) }}
          </Col>
          <Col
            v-if="errorCode === 116007"
            span="16"
            style="font-size:16px">{{ $t('key1005768').replace('{pickList}', pickListText) }}
          </Col>
          <Col
            v-if="errorCode === 116009"
            span="16"
            style="font-size:16px">{{ $t('key1005769').replace('{pickList}', pickListText) }}
          </Col>
          <Col
            v-if="errorCode === 116011"
            span="16"
            style="font-size:16px">{{ $t('key1005660').replace('{pickList}', pickListText) }}
          </Col>
        </Row>
      </div>
      <template #footer>
        <Button v-if="errorCode !== 116005" style="marginRight:210px;" @click="cancel">{{ $t('key1000593') }} </Button>
        <Button v-if="errorCode === 116005" style="marginRight:210px;" @click="ok">{{ $t('key1000096') }} </Button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { alias1bddc4d174174d47a6dfb0437dace856 } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import api from '@/api/';
import {getUserInfoByUserIdsData, getUserNameByUserId} from "@/utils/common";

export default {
  name: "sorting",
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      sortingStatus: false, // 刷新表格数据
      pickList: null, // 拣货单单号
      loading: false,
      sortingData: [],
      pickNo: null,
      sortingColumn: [
        {
          title: 'NO',
          width: 60,
          align: 'center',
          key: 'index',
          fixed: 'left',
          type: 'index',
        }, {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000278'),
          key: 'opts',
          align: 'center',
          width: 100,
          render: (h, params) => {
            return h('optsButtonList', {
              props: {
                operationList: [
                  {
                    text: alias1bddc4d174174d47a6dfb0437dace856.t('key1003189'),
                    show: true,
                    clickFn: () => {
                      this.cancelPick(params.row.pickingGoodsId);
                    }
                  }
                ]
              },
              on: {
                setWidth(value) {
                  if (value) {
                    this.optionBtnWidth.push(value);
                    let width = Math.max(...this.optionBtnWidth)
                    params.column.width = width;
                    this.$forceUpdate();
                  }
                }
              }
            });
          }
        }, {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1003605'),
          key: 'pickingGoodsNo',
          align: 'center'
        }, {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1002456'),
          key: 'packageGoodsType',
          align: 'center',
          render: (h, params) => {
            return h('div', alias1bddc4d174174d47a6dfb0437dace856.t('key1000178'));
          }
        }, {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1003107'),
          key: 'sortingStartTime',
          align: 'center',
          sortable: true,
          render: (h, params) => {
            let date = this.$uDate.dealTime(params.row.sortingStartTime);
            return h('div', date);
          }
        }, {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1005665'),
          key: '',
          align: 'center',
          render: (h, params) => {
            let date = this.$uDate.dealTime(params.row.sortingStartTime);
            return h('div', this.getTime(date));
          }
        }, {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1005778'),
          key: 'sortingStatus',
          align: 'center',
          render: (h, params) => {
            if (params.row.sortingStatus === '0') {
              return h('div', alias1bddc4d174174d47a6dfb0437dace856.t('key1001158'));
            } else if (params.row.sortingStatus === '1') {
              return h('div', alias1bddc4d174174d47a6dfb0437dace856.t('key1005779'));
            } else if (params.row.sortingStatus === '2') {
              return h('div', alias1bddc4d174174d47a6dfb0437dace856.t('key1005780'));
            }
          }
        }, {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1005781'),
          key: 'sortingUserId',
          fixed: 'right',
          align: 'center',
          render: (h, params) => {
            return h('span', getUserNameByUserId(params.row.sortingUserId, v));
          }
        }
      ],
      pickingListStatus: false, // 异常弹框
      pickingListModelStatus: false,
      errorCode: null, // 异常
      pickStartTime: null, // 分拣作业开始时间 异常用到
      restPick: 'no', // 是否需要重新分拣
      pickListText: ''
    };
  },
  created() {
    let v = this;
    v.getList();
  },
  updated: function () {
    this.$nextTick(() => {
      this.$refs.packListIpt.focus();
    });
  },
  methods: {
    getList() {
      // 获取正在进行的分拣列表
      let v = this;
      if (v.getPermission('wmsPickingGoods_querySortingList')) {
        let list = [];
        v.tableLoading = true;
        v.axios.get(api.get_wmsPickingGoods_querySortingList).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas || [];
            if (data.length > 0) {
              data.forEach((n, i) => {
                list.push(n.sortingUserId);
              });
              if (list.length > 0) {
                getUserInfoByUserIdsData(list, v).then((userData) => {
                  v.sortingData = list;
                  v.$nextTick(() => {
                    v.tableLoading = false;
                  });
                });
              }
            } else {
              v.tableLoading = false;
            }
          } else {
            v.tableLoading = false;
          }
        });
      } else {
        v.gotoError(); // 无权限
      }
    },
    pickStart() {
      // 开始分拣
      let v = this;
      if (v.getPermission('wmsPickingSorting_startSorting')) {
        if (v.pickList !== null && v.pickList !== '') {
          v.pickListText = v.pickList;
          v.axios.get(api.get_wmsPickingGoods_startSorting + '?pickingGoodsNo=' + v.pickList + '&warehouseId=' + v.getWarehouseId()).then(response => {
            if (response.data.code === 0) {
              let data = response.data.datas;
              localStorage.setItem('pickingInfos', JSON.stringify(data));
              v.$router.push('/sortingInfo');
            }
            v.pickList = '';
          });
        } else {
          v.pickingListModelStatus = false;
        }
      } else {
        v.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1005782'));
      }
    },
    ok() {
      // 异常 已分拣完成，重新开启分拣
      let v = this;
      if (v.restPick === 'yes') {
        // 重新开启分拣
        if (v.pickList !== null) {
          v.axios.get(api.get_wmsPickingGoods_startSorting + '?pickingGoodsNo=' + v.pickList + '&flag=1' + '&warehouseId=' + v.getWarehouseId()).then(response => {
            if (response.data.code === 0) {
              let data = response.data.datas;
              localStorage.setItem('pickingInfos', JSON.stringify(data));
              window.location.href = '//' + window.location.host + '/wms-service/sortingInfo.html';
            } else if (response.data.code === 116007) {
              v.$nextTick(() => {
                v.$refs.packListIpt.focus();
              });
            }
          });
        }
      } else if (v.restPick === 'no') {
        v.pickingListModelStatus = false;
        v.$nextTick(() => {
          v.$refs.packListIpt.focus();
        });
      }
    },
    cancel() {
      // 关闭异常弹框
      let v = this;
      v.pickingListModelStatus = false;
      v.$nextTick(() => {
        v.$refs.packListIpt.focus();
      });
    },
    cancelPick(pickingGoodsNo) {
      // 撤销分拣
      let v = this;
      v.axios.post(api.post_wmsPickingGoods_cancelSorting + pickingGoodsNo).then(response => {
        if (response.data.code === 0) {
          v.sortingStatus = true;
        }
      });
    },
    getTime(time) {
      let v = this;
      let nowTime = v.$uDate.getNowTime() / 1000; // 现在的时间(秒)
      let oldTime = new Date(time).getTime() / 1000; // 分拣开始的时间(秒)
      let theTime = parseInt(nowTime - oldTime);
      let theTime1 = 0; // 分
      let theTime2 = 0; // 时
      if (theTime / 60 / 60 >= 1) {
        theTime2 = parseInt(theTime / 60 / 60);
        theTime = theTime % (60 * 60);
        if (theTime / 60 >= 1) {
          theTime1 = parseInt(theTime / 60);
        }
        return theTime2 + 'hour ' + theTime1 + 'min';
      } else if (theTime / 60 / 60 < 1 && theTime / 60 >= 1) {
        theTime1 = parseInt(theTime / 60);
        return theTime1 + 'min';
      } else if (theTime > 0 && theTime < 1) {
        return 'Less than a minute';
      }
    }
  },
  watch: {
    sortingStatus(n, o) {
      let v = this;
      if (n) {
        v.getList();
        v.sortingStatus = false;
      }
    }
  },
};
</script>

<style lang="less" scoped>
.title {
  font-size: 22px;
  color: #333;
  font-weight: bold;
  margin-bottom: 15px;
}
</style>
